<template>
  <div class="integration-webtop view">
    <div class="integration-webtop__logo">
      <img src="../assets/stream-logo.png" alt="stream-logo" />
      <template v-if="step !== 'activate'">
        <FontAwesomeIcon :icon="['fal', 'long-arrow-right']" size="2x" />
        <img :src="`https://${data.account}/api/client/logo`" />
      </template>
    </div>
    <component :is="stepComp" v-model="data" @navigate="$ev => (stepComp = $ev)"> </component>
  </div>
</template>

<script>
import Steps from '@/components/Webtop/Steps'
import Storage from '@/mixins/Storage'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  mixins: [
    Storage({ target: 'stepComp', key: 'webtop-step' }),
    Storage({ target: 'data', key: 'webtop-data', deep: true, map: v => JSON.parse(v) }),
  ],
  props: {
    step: {
      type: String,
      enum: ['activate', 'units', 'completed'],
      default: 'activate',
    },
    account: String,
    id: String,
    token: String,
  },
  data() {
    return {
      data: {
        map: {},
        account: null,
        token: null,
        id: null,
      },
    }
  },
  computed: {
    stepComp: {
      get() {
        return this.step
      },
      set(step) {
        if (this.$route.params.step === step) return
        return this.$router.replace({ name: this.$route.name, params: { step } })
      },
    },
  },

  beforeMount() {
    if (!this.data.account && this.account) {
      this.data.account = this.account
      this.data.id = this.id
      this.data.token = this.token
    }
  },

  components: {
    ...Steps,
    FontAwesomeIcon,
  },
}
</script>

<style lang="scss">
.integration-webtop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $background-grey;

  &__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 2rem;
    margin-bottom: 3rem;

    svg {
      opacity: 0.3;
    }

    img {
      max-height: 35px;
    }
  }

  .kvass-card {
    max-width: 600px;
    width: 100%;

    &__footer-content {
      justify-content: center;
      align-items: start;
    }
  }
}
</style>
