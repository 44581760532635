<template>
  <Card class="integration-webtop-completed">
    <template #header>
      <slot name="header" />
    </template>
    <template #default>
      <h1>
        <FontAwesomeIcon :icon="['fal', 'check-circle']" />
        Fullført
      </h1>
      <p>
        Aktiveringen av integrasjonen har blitt fullført og løsningene kan nå kommunisere med hverandre. Du kan nå lukke
        dette vinduet.
      </p>
    </template>
  </Card>
</template>

<script>
import Card from '@kvass/card'
import { ButtonComponent } from 'vue-elder-button'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  components: {
    ButtonComponent,
    Card,
    FontAwesomeIcon,
  },
}
</script>

<style lang="scss">
.integration-webtop-completed {
  .kvass-card__content {
    text-align: center;
  }

  svg {
    font-size: 0.8em;
    opacity: 0.7;
    margin-right: 0.1em;
  }
}
</style>
